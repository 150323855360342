.login-modal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .modal-content {
    /* background: linear-gradient(145deg, #1e1e2f, #2e2e3f); */
    padding: 30px;
    border-radius: 15px;
    width: 400px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    position: relative;
    /* text-align: center; */
    text-align: left;
    color: #fff;
  }
  
  .expire-model {
    color: #fff;
  }
  
  .titleBox {
    position: relative;
  }
  
  .closeBox {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  
  .expire-close {
    cursor: pointer;
    color: #fff;
    font-size: 1.2em;
  }
  
  .expire-heading p {
    font-size: 1.8em;
    font-weight: bold;
    margin: 0;
    color: #ff6b6b;
  }
  
  .expire-description {
    font-size: 50px;
    color: #ff6b6b;
    margin: 0;
  }
  
  h2 {
    margin-top: -20px;
    font-size: 1.8em;
    color: #ff6b6b;
  }
  
  .text-center p {
    font-size: 1.1em;
    line-height: 1.5em;
    color: #000000;
  }
  
  .btn-own10 {
    background: linear-gradient(145deg, #ff6b6b, #ff4b4b);
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 5px 15px rgba(255, 75, 75, 0.3);
  }
  
  .btn-own10:hover {
    background: linear-gradient(145deg, #ff4b4b, #ff2b2b);
    transform: translateY(-2px);
  }
  

  .modal-dialog .multi-range-slider  {

    border: none !important;
    box-shadow: none !important;
  }