.App {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }