.bulk-upload-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  /* background-color: #f9f9f9; */
  /* border: 1px solid black; */
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.upload-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="file"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-btn {
  padding: 10px 15px;
  background-color: #6200e8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:disabled {
  background-color: #ccc;
}

.loading-message {
  text-align: center;
  font-size: 16px;
  color: #ff9900;
  margin-top: 10px;
}

.response-message {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  color: green;
}

.response-container {
  margin-top: 20px;
}

h3 {
  margin-bottom: 10px;
}

.scrollable-log {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
}

.property-item {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.property-item:last-child {
  border-bottom: none;
}
.custome-container-bulk {
  margin-top: 100px;
  padding: 50px;
  border: 1px solid black;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
