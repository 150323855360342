.flat-modal-dialog .modal-dialog {
    max-width: 80% !important;
}


.flat-modal-content {
    background-color: transparent !important;
}

.flat-modal-dialog .bg-lightblue {
    background-color: #ecf1f6;
    border-radius: 25px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    padding: 44px 39px;
}

.flat-modal-dialog #property-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flat-modal-dialog .flatseeker-container #property-heading h2 {
    text-transform: capitalize !important;
    padding: 15px 30px 15px 0px !important;
    font-size: 25px !important;
    text-align: left;
}
.flat-modal-dialog #IconGroup {
    font-size: 19px;
    display: flex;
    color: grey;
    font-weight: normal;
    justify-content: space-around !important;
}
.flat-modal-dialog .ShareIcons {
    cursor: pointer;
    background: white;
    width: 40px;
    height: 40px;
    display: flex;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    align-items: center;
    border-radius: 50%;
    margin-right: 0;
}

.flat-modal-dialog #propertyPhotos {
    gap: 8px;
    margin-top: 0px;
    width: 100%;
    display: flex;
    min-height: 50vh;
}

.flat-modal-dialog .left-photo {
    max-height: max-content !important;
    width: 40% !important;
    /* background: white; */
    margin-top: 27px;
    display: flex;
    /* justify-content: center; */
    background-color: white !important;
    align-items: center;
    flex-direction: column;
    height: 100% !important;
}

.flat-modal-dialog .left-photo img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 4px;
}

.flat-modal-dialog .flatseeker-container .detailsBox {
    margin-top: 0px;
    background-color: #fff;
    margin: 1.5rem 0;
}
.flat-modal-dialog .boxHeader {
    padding: 1rem 2rem;
    font-size: 20px;
    color: black;
    letter-spacing: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
}

.flat-modal-dialog .innerDetailsBox {
    padding: 1rem 2rem;
}

.flat-modal-dialog .right{

    width: 60% !important;
}

.flat-modal-dialog .flat-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    text-align: left;
}

.flat-modal-dialog .labelTextForDetail {
    color: rgba(0, 0, 0, 0.5);
    margin-top: 20px;
    text-align: left;
}

.flat-modal-dialog .valueTextForDetail {

    text-align: left;
}

.flat-modal-dialog .SingleDetailBox .bg-property {
    border: none;
    padding: 0;
    width: 100%;
    padding: 10px 0px;
    /* border: 1px solid #00000033; */
    margin-top: 8px;
    border-radius: 6px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.8);
    line-height: 2;

}

.flat-modal-dialog .SingleDetailBox .bg-property .propert-preference {
    list-style: none;
    display: flex;
    padding-left: 0;
    flex-wrap: wrap;
}
.flat-modal-dialog .SingleDetailBox .valueTextForDetail ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
    margin-bottom: 0;
    margin-top: 0;
}

.flat-modal-dialog .SingleDetailBox .bg-property .propert-preference li {
    text-transform: capitalize;
    border: 1px solid #e9ecef;
    border-radius: 20px;
    padding: 8px 21px;
    background: #e9ecef;
    margin: 0 5px 0 0px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.flat-modal-dialog .AmenityBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 100%; */
}
.flat-modal-dialog .flatseeker-container .flat-details span {
    font-size: 12px;
    text-align: center;
}

.flat-modal-dialog .featuresRow {
    display: flex;
    /* overflow-x: scroll; */
    /* justify-content: center; */
    flex-wrap: nowrap;
    overflow-x: scroll;
}
.flat-modal-dialog .featuresRow .card {
    margin: 10px 10px 35px 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    min-width: 300px !important;
    padding: 0 !important;
    border-radius: 5px !important;
}
.flat-modal-dialog .phone-width img {
    object-fit: cover;
}
.flat-modal-dialog .phone-width .card-body {
    border-radius: 0 0 5px 5px !important;
    background-color: white;
    text-align: left;
}
.flat-modal-dialog .card-text {
    margin-bottom: 10px !important;
}

.flat-modal-dialog .preferences-details ul {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 0px;
    gap: 10px;
    align-items: center;
}
.flat-modal-dialog .similar-preferences-details ul li {
    font-size: 10px;
    background: rgb(0, 0, 0);
    color: rgb(239, 239, 239);
    padding: 5px 6px;
    border-radius: 8px;
    text-transform: capitalize;
    list-style-type: none;
    letter-spacing: 1px;
}
.flat-modal-dialog #custom-title .card-title {
    line-height: 1.2 !important;
}


.body-for-mobile{

    display: none;
}
.body-for-laptop{

    display: block;
}


.container-laptop{
    display: block;
}

.container-mobile{

    display: none;
}


.container-mobile .left-photo {
    
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;;

}

.container-mobile .left-photo img{
    
    height: 250px !important;
    width: 250px !important;
    border-radius: 50% !important;

}


.container-mobile .right{
    
    width: 100% !important;

  

}

.container-mobile .SingleDetailBox{
    
    flex-direction: column !important;

}


.container-mobile .valueTextForDetail{

    /* width: 100% !important; */
}


.container-mobile .valueTextForDetail ul{

    margin-left: 0px;
    display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0px;
}


.container-mobile .valueTextForDetail ul li{

    margin-left: 0px;
    border: 1px solid #e9ecef;
    border-radius: 20px;
    padding: 8px 21px;
    background: #e9ecef;
    margin: 0 5px 0 0px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    list-style-type: none;
}














@media screen and (max-width: 767px) and (min-width: 200px) {
    .flatseeker-container #CarouselImage {
        height: 150px;
        width: 150px;
        border-radius: 50% !important;
        object-fit: cover;
        object-position: top;
    }
}

@media screen and (min-width: 377px) and (max-width: 426px) {
    #CarouselImage {
        height: 210px;
    }
}




















@media only screen and (min-width: 1024px) and (max-width: 2000px) {
    .flat-modal-dialog .amenities-size {
        width: 30px;
        height: 30px;
    }
}

@media  only screen and (max-width: 768px) {


    .container-laptop{
        display: none;
    }
    
    .container-mobile{
    
        display: block;
    }
    

    .flat-modal-dialog #propertyPhotos {

        flex-direction: column !important;
    }

    .flat-modal-dialog .left-photo {

        width: 100% !important;
    }
    .flat-modal-dialog .right {

        width: 100%  !important;
    }

    .modal-body {
        position: relative !important;
        flex: 1 1 auto !important;
        padding: 0 !important;
        border-radius: 15px;
        background: #f7f7f9;
    }

    .bg-lightblue {
        padding: 0 !important;
        background-color: #ffffff !important;
        border-radius: 25px !important;
        box-shadow: none !important;
    }
    .flatseeker-container #property-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .flatseeker-container #property-heading h2 {
        padding: 15px 30px 15px 0px;
        font-size: 14px !important;
        text-align: center;
    }
    .flatseeker-container #IconGroup {
        display: flex;
        flex-direction: row;
        gap: 15px;
        margin-bottom: 15px;
        font-size: 12px;
        color: grey;
        font-weight: normal;
    }
    .ShareIcons {
        height: 32px;
        width: 32px;
        line-height: 32px;
        background-color: rgb(242, 243, 248);
        border-radius: 50%;
        text-align: center;
    }

    .modal-controls .boxHeader {
        padding: 15px !important;
        font-weight: 700 !important;
        font-size: 14px !important;
    }
    .flat-modal-dialog .modal-dialog {
        max-width: 100% !important;
    }
    .flat-modal-dialog .valueTextForDetail{

        width: 100% !important;
        width: auto !important;
        margin-top: 0px !important;
        padding: 5px 5px !important;
    }
    .flat-modal-dialog .labelTextForDetail{

        margin-top: 0px !important;
        font-size: 15px;
        font-weight: 600;
        margin: 6px 0px;
        width: 100% !important;
    }

    .flat-modal-dialog  .innerDetailsBox {
        padding: 15px !important;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 0px !important;
    }
    .flat-details {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .flat-modal-dialog  .LaptopHide {
        /* display: none; */
    }
    .flat-modal-dialog  .SingleDetailBox {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .valueTextForDetail {
        line-height: 1.2;
        font-size: 13px;
    }
    .flat-modal-dialog .SingleDetailBox .valueTextForDetail ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 5px !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }

    .SingleDetailBox .bg-property .propert-preference {
        list-style: none !important;
        display: flex !important;
        padding-left: 0;
    }

    .flat-modal-dialog .SingleDetailBox .valueTextForDetail ul li{
        border: 1px solid #e9ecef !important; 
        border-radius: 20px !important;
        padding: 8px 21px !important;
        background: #e9ecef !important;
        margin: 0 5px 0 0px !important;
        line-height: 21px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        text-transform: capitalize !important;

    }

    .labelTextForDetail {

        margin: 10px 0px !important;
    }

    .flat-modal-dialog .SingleDetailBox .valueTextForDetail ul{
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 5px;
        margin-bottom: 0;
        margin-top: 0;
    }

    .flat-mobile-hide{

        display: none;
    }

   

    .body-for-mobile{

        display: block ;
    }

    .body-for-laptop{

        display: none ;
    }

}





